<template>
  <div class="login-section-outer row">
    <div class="col-lg-6 col-md-6 col-12 login-banner-outer ps-0">
      <div
        class="
          d-flex
          flex-column
          min-vh-100
          justify-content-center
          align-items-center
          custom-baner-logo-height-mb
        "
      >
      <img v-if="this.client_info.maa59 == null || this.client_info.maa59 == ''" class="img-fluid" src="/assets/images/login-banner.jpg"
          alt="partylogo" />
        <img v-if="this.client_info.maa59 != null && this.client_info.maa59 != ''" class="img-fluid" :src="party_banner" alt="partylogo" />
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 login-form-outer-border">
      <div class="login-form-outer d-flex align-items-center">
        <div class="login-form-inner">
          <div class="login-header-image text-center">
            <div v-if="onchangepartylogo == ''">
              <img v-if="this.client_info.maa47 == null || this.client_info.maa47 == ''"
                src="/assets/images/meraneta-logo-black.png" class="img-fluid" alt="party-head-img" width="146" />
              <img v-if="this.client_info.maa47 != null && this.client_info.maa47 != ''" :src="party_logo"
                class="img-fluid" alt="party-head-img" width="146" />
            </div>
            <div v-else>
              <img :src="this.onchangepartylogo" class="rounded-circle login-img-border" alt="party-head-img"
                width="146" height="146" />
            </div>
          </div>
          <div class="login-header-sublabel">{{ this.onchangepartyname }}</div>
          <div class="login-header-label">Forgot Password?</div>
          <div class="login-form-layout">
            <div class="row">
              <div class="col-12">
                <div class="custom-form-group">
                  <label for="formUsernameInput" class="form-label"
                    >Mobile No.<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    v-model="username"
                    class="form-control"
                    id="formUsernameInput"
                    placeholder="Enter Your Registered Mobile No."
                    autocomplete="off"
                    @paste="onPasteMobile"
                    @keypress="onlyNumberMobile"
                    maxlength="10"
                    tabindex="1"
                    v-on:keyup.enter="ForgotPassword()"
                  />
                  <div class="custom-error" v-if="v$.username.$error">
                    {{ v$.username.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group">
                  <button
                    class="btn login-submit-btn"
                    @click="ForgotPassword()"
                    type="submit"
                    tabindex="2"
                  >
                    Send Password
                  </button>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group d-flex justify-content-center">
                  <router-link
                    class="nav-link custom-backtologin-btn"
                    to="/login"
                    ><i class="pi pi-arrow-left me-2"></i>Back to
                    Login</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      username: '',
      client_info: "",
      party_logo: "",
      baseUrl: window.location.origin,
      party_banner: "",
      onchangepartylogo: "",
      onchangepartyname: "",
      partyloginList: [],
      client_path: '',
      localstorageimgpath: '',
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  watch: {
    username() {
      this.v$.$validate();
      if (this.username.length == 10) {
        this.getLoginUserByMobileNumber(this.username);
      } else {
        this.onchangepartylogo = "";
        this.onchangepartyname = '';
      }
    },
  },
  validations() {
    return {
      username: { required: helpers.withMessage('Please enter Mobile No.', required), minLength: helpers.withMessage('Enter vaild Mobile No.', minLength(10)) },
    };
  },
  mounted() {
    var usernamemobile = localStorage.localusername;
    this.username = usernamemobile;
    this.client_info = JSON.parse(localStorage.client_info);
    if (this.baseUrl == 'http://localhost:8080' || this.baseUrl == 'http://localhost:8081') {
      this.client_path = this.client_info.maa24;
    } else {
      this.client_path = localStorage.masterbucketname;
    }
    this.party_logo = "https://storage.googleapis.com/" + this.client_path + "/Client/Logo/" + this.client_info.maa6;
    this.party_banner = "https://storage.googleapis.com/" + this.client_path + "/Client/PartyBanner/" + this.client_info.maa59;
    this.localstorageimgpath = this.client_info.maa24;
  },

  methods: {
    getLoginUserByMobileNumber(e) {
      this.ApiService.getLoginUserByMobileNumber({ login_id: e }).then((data) => {
        if (data.status == 200) {
          this.partyloginList = data.data;
          if (this.partyloginList.ak90) {
            this.onchangepartylogo = "https://storage.googleapis.com/" + this.localstorageimgpath + "/voterphoto/" + this.partyloginList.ak90;
          } else if (this.partyloginList.ak33) {
            this.onchangepartylogo = "https://storage.googleapis.com/" + this.localstorageimgpath + "/aadharphoto/" + this.partyloginList.ak33;
          }
          else {
            this.onchangepartylogo = '';
          }
          this.onchangepartyname = this.partyloginList.ak5;
        } else {
          this.errormsg = data.message;
          this.onchangepartylogo = '';
          this.onchangepartyname = '';
        }
      });
    },
    ForgotPassword() {
      this.v$.$validate(); 
      if (!this.v$.$error) {
        this.ApiService.forgotpassword({ mobile_no: this.username }).then(
          (data) => {
            if (data.status == 200) {
              if (data.data != "") {
                this.clientUserList = data.data;
              } else {
                
                this.username = "";
                var successMsg = data.message;
                this.$toast.open({
                  message: successMsg,
                  type: "success",
                  duration: 3000,
                  position: "top-right",
                });
                setTimeout(() => {
                  this.v$.$reset();
                }, 0);
                this.$router.push("/login");
              }
            } else {
              this.loading = false;
              this.clientUserList = null;
              var errorMsg = data.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
          }
        );
      } else {
        this.$error;
      }
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput =  document.getElementById("formUsernameInput");
      if((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)){
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.custom-backtologin-btn {
  font-size: 13px;
  font-family: 'AcuminPro-Regular';
  letter-spacing: 0.26px;
color: #5266E5;
padding: 0;
  line-height: 13px;
}
</style>